import React, { useEffect, useState, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { api } from "./api";
import "./styles/default.css";
import TopBar from "./components/TopBar/TopBar";
import Buildings from "./pages/Buildings";

// Lazy load components for code splitting
const BuildingDashboard = React.lazy(() => import("./pages/BuildingDashboard"));
const BuildingActivities = React.lazy(() => import("./pages/BuildingActivities"));
const BuildingGroups = React.lazy(() => import("./pages/BuildingGroups"));
const BuildingDevices = React.lazy(() => import("./pages/BuildingDevices"));
const BuildingSettings = React.lazy(() => import("./pages/BuildingSettings"));
const BuildingAlerts = React.lazy(() => import("./pages/BuildingAlerts"));
const BuildingTimers = React.lazy(() => import("./pages/BuildingTimers"));
const Subscription = React.lazy(() => import("./pages/Subscription"));
const ManagementView = React.lazy(() => import("./pages/ManagementView"));
const Login = React.lazy(() => import("./pages/Login"));
const PasswordReset = React.lazy(() => import("./pages/PasswordReset"));

const App = () => {
  const [buildingId, setBuildingId] = useState(undefined);
  const [building, setBuilding] = useState(undefined);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Check access token
    const storageAvailable = (type) => {
      try {
        var storage = window[type],
          x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
      } catch (e) {
        return false;
      }
    };

    if (storageAvailable("localStorage")) {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        window.accessToken = accessToken;
        setLoggedIn(true);
      }
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      if (buildingId) {
        api("/building/basic/" + buildingId).then((res) => {
          setBuilding(res);
        });
      } else {
        setBuilding(undefined);
      }
    }
  }, [loggedIn, buildingId]);

  return (
    <>
      {loggedIn ? (
        <>
          <TopBar
            building={building}
            setLoggedIn={setLoggedIn}
            loggedIn={loggedIn}
          />
          <div className="app_content">
            <Suspense fallback={<div>Loading...</div>}>
              <Route
                path="/buildings/new"
                render={({ match, history }) => (
                  <BuildingSettings
                    match={match}
                    history={history}
                    setBuildingId={setBuildingId}
                  />
                )}
              />
              <Route
                exact={true}
                path="/building/:buildingId"
                render={({ match }) => (
                  <BuildingDashboard match={match} setBuildingId={setBuildingId} />
                )}
              />
              <Route
                path="/building/activities/:buildingId"
                render={({ match }) => (
                  <BuildingActivities
                    match={match}
                    setBuildingId={setBuildingId}
                  />
                )}
              />
              <Route
                path="/building/groups/:buildingId"
                render={({ match }) => (
                  <BuildingGroups match={match} setBuildingId={setBuildingId} />
                )}
              />
              <Route
                path="/building/devices/:buildingId"
                render={({ match }) => (
                  <BuildingDevices match={match} setBuildingId={setBuildingId} />
                )}
              />
              <Route
                path="/building/settings/:buildingId"
                render={({ match, history }) => (
                  <BuildingSettings
                    match={match}
                    history={history}
                    setBuildingId={setBuildingId}
                  />
                )}
              />
              <Route
                path="/building/alerts/:buildingId"
                render={({ match }) => (
                  <BuildingAlerts match={match} setBuildingId={setBuildingId} />
                )}
              />
              <Route
                path="/building/timers/:buildingId"
                render={({ match }) => (
                  <BuildingTimers match={match} setBuildingId={setBuildingId} />
                )}
              />
              <Route path="/subscription" component={Subscription} />
              <Route path="/buildings" exact={true} component={Buildings} />
              <Route path="/admin" component={ManagementView} />
              <Route path="/" exact={true} component={Buildings} />
            </Suspense>
          </div>
        </>
      ) : (
        <Switch>
          <Suspense fallback={<div>Loading...</div>}>
            <Route path="/password-reset" component={PasswordReset} />
            <Route path="/" render={() => <Login setLoggedIn={setLoggedIn} />} />
          </Suspense>
        </Switch>
      )}
    </>
  );
};

export default App;
